<template>
    <div class="index">
        <el-container class="el-container"
                      v-loading="loading" element-loading-text="加载中请勿关闭网页">
            <el-header class="el-header"><h3>免费在线HEIC转图片</h3></el-header>
            <el-main class="el-main">
                <!--                <div class="to-edit">-->
                <!--                    <span>转换类型：</span>-->
                <!--                    <el-select v-model="toType" placeholder="请选择">-->
                <!--                        <el-option-->
                <!--                                v-for="item in options"-->
                <!--                                :key="item.value"-->
                <!--                                :label="item.label"-->
                <!--                                :value="item.value">-->
                <!--                        </el-option>-->
                <!--                    </el-select>-->
                <!--                </div>-->
                <div class="to-edit" style="margin-top: 20px;">
                    <span>压缩质量：</span>
                    <el-input-number v-model="quality" :precision="1" :step="0.1" :min="0.1" :max="1"
                                     label="压缩质量"></el-input-number>
                </div>
                <div>
                    <el-upload
                            class="upload"
                            ref="upload"
                            :drag="true"
                            :on-change="handleChange"
                            :auto-upload="false"
                            :multiple="true"
                            :limit="20"
                            :show-file-list="true">
                        <i class="el-icon-upload"></i>
                        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                        <div class="el-upload__tip">上传HEIC文件，加载完成会直接打包成压缩包并下载，批量最多20张图片。</div>
                        <div class="el-upload__tip" style="color:#F56C6C;">加载期间请勿关闭此页面！</div>
                    </el-upload>
                </div>
            </el-main>
            <el-footer class="el-footer">
                <el-link type="info" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=42018502006309">
                    <el-image :src="beian"/>
                    鄂公网安备 42018502006309号
                </el-link>
                <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                <el-link type="info" href="https://beian.miit.gov.cn/">鄂ICP备2022015722号</el-link>
            </el-footer>
        </el-container>
    </div>
</template>

<script>
    import heic2any from "heic2any";

    import JSZip from 'jszip'

    import FileSaver from 'file-saver'

    export default {
        created() {
            document.title = '免费在线HEIC转图片';
            this.$notify({
                title: '用户告知',
                message: '图片转换是在用户本地进行，不会获取任何用户数据！',
                type: 'warning'
            });

        },
        data() {
            return {
                imgNumber: 0,
                imgto: 0,
                loading: false,
                options: [{value: 'image/png', label: 'png'},
                    {value: 'image/jpeg', label: 'jpg'},
                    {value: 'image/gif', label: 'gif'}],
                toType: 'image/jpeg',
                quality: 1,
                imgList: [],
                beian: require('@/assets/common/beian.png')
            }
        },
        methods: {
            handleChange(file) {
                let that = this
                that.loading = true
                const uploadTypes = ['HEIC', 'heic']
                const filetype = file.name.replace(/.+\./, '')
                const filename = file.name.substring(0, file.name.lastIndexOf("."))
                if (uploadTypes.indexOf(filetype.toLowerCase()) === -1) {
                    this.$message.warning({
                        message: '请上传后缀名为HEIC文件'
                    })
                    that.imgList = []
                    that.imgNumber = 0
                    that.imgto = 0
                    that.loading = false
                    that.$refs['upload'].clearFiles();
                    return false
                }
                that.imgto = that.imgto + 1;
                that.imgList.push({
                    src: file.raw,
                    name: filename
                })
                let upload_img = document.getElementsByClassName('upload')
                if (upload_img && upload_img.length > 0) {
                    let upload = upload_img[0].getElementsByTagName('input')
                    if (upload && upload.length > 0 && upload[0].files && upload[0].files.length > 0) {
                        if (upload[0].files.length === that.imgto) {
                            that.heicToany();
                        }
                    }
                }

                return true
            },
            heicToany() {
                let that = this
                let imgList = that.imgList
                for (let i = 0; i < imgList.length; i++) {
                    heic2any({
                        blob: imgList[i].src,  // 将heic转换成一个buffer数组的图片
                        toType: that.toType,
                        quality: that.quality // 图片的质量，参数在0-1之间
                    }).then(result => {
                        let file = new FileReader();
                        file.readAsDataURL(result);
                        file.onload = function (imageFile) {
                            that.imgList[i].src = imageFile.target.result
                            that.imgNumber = that.imgNumber + 1;
                            if (that.imgNumber === that.imgto) {
                                that.downLoad();
                            }
                        };
                    })
                }
            },
            downLoad() {
                let that = this
                let tonumber = 0;
                let obj = that.options.find(o => o.value === that.toType);
                let blogTitle = Date.now() + obj.label //图片压缩包名称
                let zip = new JSZip()
                let imgs = zip.folder(blogTitle)
                for (let i = 0; i < this.imgList.length; i++) {
                    let image = new Image();
                    image.src = this.imgList[i].src; //给图片对象写入base64编码的svg流
                    let canvas = document.createElement('canvas'); //准备空画布
                    let context = canvas.getContext('2d'); //取得画布的2d绘图上下文
                    image.onload = function () { //需要图片加载完成在执行canvas绘制
                        tonumber++
                        context.drawImage(image, 0, 0, image.width, image.height);
                        canvas.toDataURL('image/png');
                        if (tonumber === that.imgNumber) {
                            for (let k = 0; k < that.imgNumber; k++) {
                                imgs.file(that.imgList[k].name + '.' + obj.label, that.imgList[k].src.substring(22), { //设置单张图片名称
                                    base64: true
                                })
                            }
                            zip.generateAsync({
                                type: 'blob'
                            }).then(function (content) {
                                // 调用 FileSaver.js
                                FileSaver.saveAs(content, blogTitle + '.zip');
                                that.loading = false
                                that.$refs['upload'].clearFiles();
                                that.imgNumber = 0
                                that.imgto = 0
                                that.imgList = []
                            });
                        }
                    }
                }
            }
        },
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .index {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }

    .upload {
        height: 300px;
        width: auto;
        display: inline-block;
        /*子元素文字会继承居中，因此要在上面写上向左边居中*/
        text-align: left;
        margin-top: 40px;
    }

    .el-container {
        width: 100%;
        min-height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .el-header {
        display: flex;
        height: 10vh;
        width: 100%;
        align-items: end;
        justify-content: center;
    }

    .el-main {
        height: calc(100vh - 14vh);
        overflow: hidden;
    }

    .el-footer {
        display: flex;
        height: 4vh;
        width: 100%;
        align-items: center;
        justify-content: center;
    }

    .to-edit span {
        font-family: "Helvetica Neue",
        Helvetica,
        "PingFang SC",
        "Hiragino Sans GB",
        "Microsoft YaHei",
        "微软雅黑", Arial,
        sans-serif;
        font-size: 13px;
    }
</style>
